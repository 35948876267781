import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Tooltip } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import T from 'prop-types';
import withLoadingState from '../../../../helpers/withLoadingState';
import EmptyStatePage from '../../../../components/EmptyState/EmptyStatePage';
import AddStaticSensor from './AddStaticSensorModal/AddStaticSensorModal';
import AddStaticSensorIrisV3 from './AddStaticSensorIrisV3Modal/AddStaticSensorIrisV3Modal';
import SensorMap from './SensorMap/SensorMap';
import { PlcType } from '../../../../attrs/plcConfigValues';
import SensorMapIrisV3 from './SensorMapIrisV3/SensorMapIrisV3';
import DatabaseTableName from '../DatabaseTableName';
import { IrisTableNameEnum } from '../../../../attrs/machines';

const labelAmend = 'machines.machine_details';

const StaticValueContent = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();

  const [addStaticModal, setAddStaticModal] = useState(false);

  const [hasSensorMachinesInitial, setHasSensorMachinesInitial] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.sensors && data.sensors.static && data.sensors.static.length > 0) {
        setHasSensorMachinesInitial(true);
      } else if (
        plcType.plc_type === PlcType.IRIS_V3 &&
        data.sensors &&
        data.sensors.iris &&
        data.sensors.iris.length > 0
      ) {
        setHasSensorMachinesInitial(true);
      }
    }
  }, [data, plcType]);

  const [hasSensorMachines] = useState(hasSensorMachinesInitial);

  const handleCloseStaticModal = () => setAddStaticModal(false);

  return (
    <>
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.METRICS_TABLE_NAME}
      />
      <Card sx={{ p: 4, my: 2, minHeight: '490px' }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Tooltip
              title={t(`machines.detail.plc_not_filled`)}
              disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
              arrow
            >
              <span>
                <Button
                  variant="outlined"
                  disabled={plcType.plc_type === PlcType.UNKNOWN}
                  sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px' }}
                  onClick={() => setAddStaticModal(true)}
                >
                  <NotifyIcon iconName="plus" fontSize="medium" />
                  {t(`${labelAmend}.sensor_static_action`)}
                </Button>
              </span>
            </Tooltip>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.sensor_static`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.sensor_static_info`)}</NotifyTypo.Subtitle1>

        {!hasSensorMachinesInitial && !hasSensorMachines && (
          <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
            <EmptyStatePage type="static_sensor" />
          </Box>
        )}

        {hasSensorMachinesInitial && (
          <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
            {plcType.plc_type === PlcType.IRIS_V3 ? (
              <SensorMapIrisV3 sensors={data} machineId={machineId} plcType={plcType} />
            ) : (
              <SensorMap sensors={data} machineId={machineId} plcType={plcType} />
            )}
          </Box>
        )}

        {plcType.plc_type === PlcType.IRIS_V3 ? (
          <AddStaticSensorIrisV3 handleClose={handleCloseStaticModal} open={addStaticModal} machineId={machineId} />
        ) : (
          <AddStaticSensor handleClose={handleCloseStaticModal} open={addStaticModal} machineId={machineId} />
        )}
      </Card>
    </>
  );
};

StaticValueContent.propTypes = {
  data: T.shape({
    sensors: T.instanceOf(Object)
  }),
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

StaticValueContent.defaultProps = {
  data: null,
  machineId: null
};

export default withLoadingState(StaticValueContent);
